import React from 'react'
import {Trianglify} from 'common/Trianglify'
import {DownloadOutlineIcon} from 'common/icons'
import styles from './Preview.module.css'

export class Preview extends React.PureComponent {
  componentDidMount () {
    window.location.href.includes('hi.florence') && this.props.onClickExport()
  }
  render () {
    const {patternOpts, onClickExport} = this.props
    return (
      <div className={styles.Preview}>
        <Trianglify patternOpts={patternOpts} />
        <div className={styles.actions}>
          {/* there's a bug that causes the tooltip to get stuck here when the modal opens */}
          <ExportButton onClick={onClickExport} />
        </div>
      </div>
    )
  }
}

const ActionButton = ({title, onClick, icon}) => {
  const Icon = icon
  return (
    <button className={styles.ActionButton}
      onClick={onClick}>
      <Icon /> {title}
    </button>
  )
}

const ExportButton = ({onClick}) =>
  <ActionButton
    title='Export'
    icon={DownloadOutlineIcon}
    onClick={onClick} />

// const ShareButton = ({onClick}) =>
//   <ActionButton
//     title='Get a shareable link'
//     icon={ShareOutlineIcon}
//     onClick={onClick} />

// const SaveButton = ({onClick}) =>
//   <ActionButton
//     title='Save this pattern to your library'
//     icon={BookmarkOutlineIcon}
//     onClick={onClick} />
