import React from 'react'
import {FormControl} from 'common/FormControl'
import {PaletteList} from './PaletteList'
import tippy from 'tippy.js'
import {tippyDefaults} from 'lib/tippyDefaults'
import {randomSlug} from 'lib/utils'
import styles from './Sidebar.module.css'
import {SegmentedControl} from 'common/SegmentedControl'
import {OrderedMap} from 'immutable'

const colorFunctions = OrderedMap({
  interpolateLinear: 'Gradient',
  sparkle: 'Sparkle',
  shadows: 'Shadows'
})

export class Sidebar extends React.PureComponent {
  constructor (props) {
    super(props)
    this._adCounter = 0
  }

  onChangeOptInt = (opt) => (e) => {
    const string = e.target.value
    try {
      const int = parseInt(string, 10)
      this.props.onChangeOpt(opt, int)
    } catch (e) {
      console.error(e)
    }
  }

  onChangeOptFloat = (opt) => (e) => {
    const string = e.target.value
    try {
      const int = parseFloat(string)
      this.props.onChangeOpt(opt, int)
    } catch (e) {
      console.error(e)
    }
  }

  onChangeColorFunctionType = (cfName) => {
    this.props.onChangeOpt('colorFunctionType', cfName)
  }

  onClickRandomPalette = () => {
    this.props.onChangeOpts({
      seed: randomSlug(),
      xColors: 'random'
    })
  }

  onChangeWidth = (width) => {
    this.props.onChangeOpt('width', width)
  }

  onChangeHeight = (height) => {
    this.props.onChangeOpt('height', height)
  }

  refreshAdOnCounter = () => {
    // this._adCounter = (this._adCounter + 1) % 4
    // if (this._adCounter === 0) {
    //   this._carbonad.refresh()
    // }
  }

  // this is hacky, and it's a good case for introducing redux into the
  // ad serving mechanism
  refreshAd = () => {
    // this._carbonad.refresh()
    // this._adCounter = 0
  }

  randomize = () => {
    // seed needs to be a string, not a float, since floating-point precision
    // errors can lead to *drastically* different results from the seeded random
    const opts = {
      seed: randomSlug(),
      // cellSizeFractional should match range settings in Sidebar
      cellSizeFractional: 0.02 + Math.random() * 0.25,
      variance: Math.random(),
      colorFunctionIntensity: Math.random()
    }
    this.props.onChangeOpts(opts)
    this.refreshAdOnCounter()
  }

  render () {
    const {patternOpts, customPalettes, onAddPalette} = this.props

    return (
      <div className={styles.Sidebar}>
        <div className={styles.row}>
          <ValidatedDimension
            className={styles.col}
            label='Width'
            title='Width of your pattern in pixels'
            defaultValue={patternOpts.width}
            min={50}
            max={3840}
            onChange={this.onChangeWidth} />

          <ValidatedDimension
            className={styles.col}
            label='Height'
            title='Height of your pattern in pixels'
            defaultValue={patternOpts.height}
            min={50}
            max={3840}
            onChange={this.onChangeHeight} />
        </div>

        <FormControl>
          <label data-tip title='How to apply your selected color palette to the geometry'>Color Pattern</label>
          <SegmentedControl
            options={colorFunctions}
            selected={patternOpts.colorFunctionType}
            onChange={this.onChangeColorFunctionType} />
        </FormControl>

        <FormControl>
          <label data-tip title='The intensity of the pattern effect'>
            Pattern Intensity
          </label>
          {/* todo - express cell size as a percentage of Math.max(width, height) */}
          <input
            type='range'
            min='0.0'
            max='1'
            step='0.01'
            value={patternOpts.colorFunctionIntensity}
            onChange={this.onChangeOptFloat('colorFunctionIntensity')} />
        </FormControl>

        <FormControl>
          <label data-tip title='The amount of randomness used when generating the pattern, as a percentage'>
            Triangle Variance
          </label>
          <input
            type='range'
            min='0'
            max='1'
            step='0.01'
            value={patternOpts.variance}
            onChange={this.onChangeOptFloat('variance')} />
        </FormControl>

        <FormControl>
          <label data-tip title='The granularity of the pattern, in pixels'>
            Cell Size
          </label>
          {/* todo - express cell size as a percentage of Math.max(width, height) */}
          <input
            type='range'
            min='0.02'
            max='0.25'
            step='0.01'
            value={patternOpts.cellSizeFractional}
            onChange={this.onChangeOptFloat('cellSizeFractional')} />
        </FormControl>

        <button
          data-tip
          title='Generate a new random seed for the pattern'
          className={styles.randomize}
          onClick={this.randomize}>
          Randomize
        </button>

        <FormControl>
          <label>Palette</label>
        </FormControl>
        <PaletteList
          customPalettes={customPalettes}
          onAddPalette={onAddPalette}
          xColors={patternOpts.xColors}
          onChange={xColors => this.props.onChangeOpt('xColors', xColors)}
          onRandom={this.onClickRandomPalette} />
      </div>
    )
  }
}

class ValidatedDimension extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: props.defaultValue,
      error: null
    }
  }

  componentDidMount () {
    tippy(this._input, {
      ...tippyDefaults,
      interactive: true,
      dynamicTitle: true,
      hideOnClick: false,
      trigger: 'manual',
      delay: [0, 0]
    })
  }

  onChange = (e) => {
    this.setState({value: e.target.value})
  }

  onValidationFailed = (message) => {
    this.setState({error: message}, () => {
      this._input._tippy.show()
    })
  }

  validateValue = () => {
    const {min, max} = this.props
    const {value} = this.state
    const int = parseInt(value, 10)
    if (!isFinite(int)) {
      this.onValidationFailed('Must be a number')
      return false
    }
    if (min > int) {
      this.onValidationFailed(`Can't be smaller than ${min}`)
      return false
    }
    if (max < int) {
      this.onValidationFailed(`Can't be larger than ${max}`)
      return false
    }

    // validation passed!
    this._input._tippy.hide()
    return true
  }

  syncState = () => {
    const {value} = this.state
    if (this.validateValue()) {
      const int = parseInt(value, 10)
      this.props.onChange(int)
    }
  }

  onKeyDown = (e) => {
    const RETURN = 13
    if (e.keyCode === RETURN) {
      this.syncState()
    }
  }

  render () {
    const {className, label, title} = this.props
    const {value, error} = this.state
    return (
      <FormControl className={className}>
        {/* todo display a ? on label hover */}
        <label data-tip title={title}>
          {label}
        </label>
        <input
          ref={r => { this._input = r }}
          title={error}
          data-original-title='true' // tippy.js hack
          onKeyDown={this.onKeyDown}
          onBlur={this.syncState}
          type='number'
          value={value}
          onChange={this.onChange} />
      </FormControl>
    )
  }
}
