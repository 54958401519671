import React from 'react'
import styles from './Modal.module.css'
import {CloseIcon} from 'common/icons'
import ReactModal from 'react-modal'

// todo: fix behind-the-modal scrolling
// https://benfrain.com/preventing-body-scroll-for-modals-in-ios/
export class Modal extends React.PureComponent {
  render () {
    const {children, onRequestClose, ...props} = this.props
    return (
      <ReactModal
        isOpen
        contentLabel='Modal'
        appElement={document.getElementById('root')}
        className={styles.Modal}
        onRequestClose={onRequestClose}
        overlayClassName={styles.overlay}
        {...props}>
        {children}
        <button className={styles.close} onClick={() => onRequestClose()}>
          <CloseIcon />
        </button>
      </ReactModal>
    )
  }
}
