import React from 'react'
import {LayoutFixed} from 'common/layouts/LayoutFixed'
import styles from './PatternsView.module.css'

export class PatternsView extends React.PureComponent {
  render () {
    return (
      <LayoutFixed>
        <div className={styles.PatternsView}>
          PatternsView
        </div>
      </LayoutFixed>
    )
  }
}
