const APP_NAME = 'stash-maps'
export const getActionTypes = (reducer, actions) => (
  actions.reduce((obj, action) => {
    obj[action] = `${APP_NAME}/${reducer}/${action}`
    return obj
  }, {})
)

export const formatCoord = (lnglat, precision = 3) => {
  const fmt = n => n.toLocaleString('en-US', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision
  })
  const {lng, lat} = lnglat
  return `${fmt(lng)}ºN, ${fmt(lat)}ºW`
}

export const moveCursorToEnd = (el) => {
  if (typeof el.selectionStart === 'number') {
    el.focus()
    el.selectionStart = el.selectionEnd = el.value.length
  } else if (typeof el.createTextRange !== 'undefined') {
    el.focus()
    var range = el.createTextRange()
    range.collapse(false)
    range.select()
  }
}

export const withDecimals =
  (minimumFractionDigits, maximumFractionDigits = undefined) => (n) =>
    n.toLocaleString('en-US', {minimumFractionDigits, maximumFractionDigits})

export const copyToClipboard = (text) => {
  var textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.height = '0px'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  let success = false
  try {
    success = document.execCommand('copy')
  } catch (err) {
    success = false
  }
  document.body.removeChild(textArea)
  return success
}

export const randomSlug = () => Math.random().toString(36).substr(7)
