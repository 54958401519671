import {Note} from 'datatypes/Note'

export const WestCoastClimbingNotes = [
  Note({
    title: 'Trout Creek',
    lngLat: {lng: -121.10859, lat: 44.80199},
    color: '#3288bd' // trad area. TODO color lookup table!
  }),
  Note({
    title: 'Joshua Tree',
    lngLat: {lng: -116.168, lat: 34.0122},
    color: '#3288bd' // trad area.
  }),
  Note({
    title: 'Squamish',
    lngLat: {lng: -123.145, lat: 49.6798},
    color: '#3288bd' // trad area.
  }),
  Note({
    title: 'Holcomb Valley Pinnacles',
    lngLat: {lng: -116.88, lat: 34.312}
    // sport area.
  }),
  Note({
    title: 'Yosemite Valley',
    lngLat: {lng: -119.573, lat: 37.7397},
    color: '#3288bd' // trad area.
  }),
  Note({
    title: 'City of Rocks',
    lngLat: {lng: -113.7037797, lat: 42.0774026}
    // sport area (ish)
  }),
  Note({
    title: 'Bishop',
    lngLat: {lng: -118.4153651, lat: 37.3664995},
    color: '#fc8d59' // bouldering area
  }),
  Note({
    title: 'Leavenworth',
    lngLat: {lng: -120.7106, lat: 47.5427},
    color: '#fc8d59' // bouldering area
  })
]
