import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import {ModalWithPreview} from 'common/ModalWithPreview'
import {AddIcon, RemoveIcon} from 'common/icons'
import {ChromePicker} from 'react-color'
import tippy from 'tippy.js'
import styles from './PalettePicker.module.css'
import {List} from 'immutable'

const COLORS = [
  '#FF6138',
  '#FFFF9D',
  '#BEEB9F',
  '#79BD8F',
  '#00A388'
]

const MAX_COLORS = 12

// TODO - make a generic <Button /> component
// TODO - make a palettes reducer
export class PalettePicker extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      palette: List(COLORS)
    }
  }

  onColorChange = (i, c) => {
    const {palette} = this.state
    this.setState({palette: palette.set(i, c)})
  }

  onAddColor = () => {
    const {palette} = this.state
    this.setState({palette: palette.push(palette.last())})
  }

  onRemoveColor = () => {
    const {palette} = this.state
    if (palette.size > 2) {
      this.setState({palette: palette.pop()})
    }
  }

  getPreviewOpts = () => {
    const {patternOpts} = this.props
    const {palette} = this.state
    return patternOpts.merge({
      xColors: palette.toJS()
    })
  }

  onSave = () => {
    const {palette} = this.state
    const {onSavePalette} = this.props
    onSavePalette(palette.toJS())
  }

  render () {
    const {onRequestClose} = this.props
    const {palette} = this.state
    const showAddButton = palette.size < MAX_COLORS

    return (
      <ModalWithPreview patternOpts={this.getPreviewOpts()} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false}>
        <div className={styles.PalettePicker}>
          <label>Click to edit colors</label>
          <Picker
            showAddButton={showAddButton}
            palette={palette}
            onColorChange={this.onColorChange}
            onAddColor={this.onAddColor}
            onRemoveColor={this.onRemoveColor} />
          <button className={styles.saveButton} onClick={this.onSave}>
            Save Palette
          </button>
        </div>
      </ModalWithPreview>
    )
  }
}

class Picker extends React.PureComponent {
  render () {
    const {palette, onColorChange, onAddColor, onRemoveColor, showAddButton} = this.props
    return <div className={styles.Picker}>
      <div className={styles.palette}>
        {/* TODO stop using index, it will bite you when you support
        removal */}
        {palette.map((c, i) => <Swatch
          key={i}
          c={c}
          onChange={c => onColorChange(i, c.hex)} />)}
      </div>
      {showAddButton && <button className={styles.addButton}
        data-tip
        onClick={onAddColor}
        title='Add color stop'>
        <AddIcon />
      </button>}
      {palette.size > 2 && <button className={styles.removeButton}
        data-tip
        onClick={onRemoveColor}
        title='Remove color stop'>
        <RemoveIcon />
      </button>}
    </div>
  }
}

class Swatch extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      mounted: false
    }
  }

  componentDidMount () {
    this.setState({mounted: true})
  }

  render () {
    const {c} = this.props
    const {mounted} = this.state
    return (
      <Fragment>
        <div
          ref={r => { this.swatch = r }}
          className={styles.Swatch}
          style={{background: c}} />
        {mounted && <ColorPicker
          color={c}
          target={this.swatch}
          onChange={this.props.onChange} />}
      </Fragment>
    )
  }
}

class ColorPicker extends React.PureComponent {
  constructor (props) {
    super(props)
    this.container = document.createElement('div')
  }

  componentDidMount () {
    const {target} = this.props
    const tippyOpts = {
      interactive: true,
      trigger: 'click',
      html: this.container,
      animateFill: false,
      animation: 'fade',
      arrow: false,
      placement: 'bottom-start',
      duration: [200, 200],
      theme: 'colorpicker'
    }
    this._tippy = tippy(target, tippyOpts)
    target._tippy.popper.classList.add('colorpicker')
  }

  render () {
    const {color, onChange} = this.props

    return ReactDOM.createPortal(
      <ChromePicker disableAlpha color={color} onChange={onChange} />,
      this.container
    )
  }
}
