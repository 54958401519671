import {Record} from 'immutable'

export const Note = Record({
  id: null,
  createdAt: null,
  updatedAt: null,
  lngLat: {lng: 0, lat: 0},
  title: '',
  tags: '',
  content: '',
  color: '#E7453C' // todo abstract this into a lookup table for better theming
})
