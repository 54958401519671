import React from 'react'
import {findDOMNode} from 'react-dom'
import {convertToTrianglifyOpts} from 'datatypes/PatternOptions'
import trianglify from 'trianglify'
import _ from 'lodash'

const preventDefault = e => e.preventDefault() && false

export class Trianglify extends React.PureComponent {
  componentDidMount () {
    this.renderCanvas()
  }

  componentDidUpdate () {
    this.renderCanvas()
  }

  renderCanvas = _.throttle(() => {
    const {patternOpts, retina} = this.props
    const canvas = findDOMNode(this)
    const canvasOpts = {
      scaling: retina ? 'auto' : false, // don't try to render at retina resolution
      applyCssScaling: false // don't try to apply scaling with CSS
    }
    trianglify(convertToTrianglifyOpts(patternOpts)).toCanvas(canvas, canvasOpts)
  }, 1000 / 15) // target 15fps for max UI responsiveness

  render () {
    const {height, width} = this.props.patternOpts

    const onContextMenu = process.env.NODE_ENV === 'production'
      ? preventDefault
      : undefined

    return <canvas height={height} width={width} onContextMenu={onContextMenu} />
  }
}
