import React from 'react'
import styles from './icons.module.css'
import cx from 'classnames'
import { ReactComponent as HeadingSvg } from 'assets/icon/heading.svg'
import { ReactComponent as IosAddSvg } from 'assets/icon/ios-add.svg'
import { ReactComponent as IosBookmarkOutlineSvg } from 'assets/icon/ios-bookmark-outline.svg'
import { ReactComponent as IosCloseSvg } from 'assets/icon/ios-close.svg'
import { ReactComponent as IosCodeSvg } from 'assets/icon/ios-code.svg'
import { ReactComponent as IosCopyOutlineSvg } from 'assets/icon/ios-copy-outline.svg'
import { ReactComponent as IosDownloadOutlineSvg } from 'assets/icon/ios-download-outline.svg'
import { ReactComponent as IosExpandSvg } from 'assets/icon/ios-expand.svg'
import { ReactComponent as IosImageOutlineSvg } from 'assets/icon/ios-image-outline.svg'
import { ReactComponent as IosImageSvg } from 'assets/icon/ios-image.svg'
import { ReactComponent as IosKeypadSvg } from 'assets/icon/ios-keypad.svg'
import { ReactComponent as IosLinkOutlineSvg } from 'assets/icon/ios-link-outline.svg'
import { ReactComponent as IosLinkSvg } from 'assets/icon/ios-link.svg'
import { ReactComponent as IosPinSvg } from 'assets/icon/ios-pin.svg'
import { ReactComponent as IosRemoveSvg } from 'assets/icon/ios-remove.svg'
import { ReactComponent as IosShareOutlineSvg } from 'assets/icon/ios-share-outline.svg'
import { ReactComponent as IosShuffleSvg } from 'assets/icon/ios-shuffle.svg'
import { ReactComponent as IosUmbrellaSvg } from 'assets/icon/ios-umbrella.svg'
import { ReactComponent as IosArrowForwardSvg } from 'assets/icon/ios-arrow-forward.svg'
import { ReactComponent as IosCubeOutlineSvg } from 'assets/icon/ios-cube-outline.svg'

const i = (Component) => ({className, ...props}) => (
  <Component className={cx(styles.icon, className)} {...props} />
)

export const AddIcon = i(IosAddSvg)
export const BookmarkOutlineIcon = i(IosBookmarkOutlineSvg)
export const CloseIcon = i(IosCloseSvg)
export const CodeIcon = i(IosCodeSvg)
export const CopyOutlineIcon = i(IosCopyOutlineSvg)
export const DownloadOutlineIcon = i(IosDownloadOutlineSvg)
export const ExpandIcon = i(IosExpandSvg)
export const HeadingIcon = i(HeadingSvg)
export const ImageIcon = i(IosImageSvg)
export const ImageOutlineIcon = i(IosImageOutlineSvg)
export const KeypadIcon = i(IosKeypadSvg)
export const LinkIcon = i(IosLinkSvg)
export const LinkOutlineIcon = i(IosLinkOutlineSvg)
export const PinIcon = i(IosPinSvg)
export const RemoveIcon = i(IosRemoveSvg)
export const ShareOutlineIcon = i(IosShareOutlineSvg)
export const ShuffleIcon = i(IosShuffleSvg)
export const UmbrellaIcon = i(IosUmbrellaSvg)
export const ArrowForwardIcon = i(IosArrowForwardSvg)
export const CubeOutlineIcon = i(IosCubeOutlineSvg)
