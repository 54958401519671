import React from 'react'
import {Router} from './Router'
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import {reducer} from 'reducers/root'
import './base.css'
import tippy from 'tippy.js'
import {tippyDefaults} from 'lib/tippyDefaults'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  // setup sentry
  Sentry.init({
   dsn: 'https://32bad2343b2c4ed6bdb036f5175b6ac0@sentry.io/1393411'
  })
}

// setup redux
const store = createStore(reducer)

export class App extends React.PureComponent {
  render () {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    )
  }
}

// This miiiiiight cause memory issues because AFAIK tippy doesn't
// destroy internal tooltip instances created via event delegation until
// the parent (in this case, document.body) is destroyed.
//
// That said, if we're hitting memory constraints because of too many
// tooltip-having divs being created & destroyed, we've probably got bigger
// issues ¯\_(ツ)_/¯
tippy(document.body, {
  ...tippyDefaults,
  target: '[data-tip]'
})
