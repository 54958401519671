import {Record, Map} from 'immutable'
import {getActionTypes} from 'lib/utils'
import {WestCoastClimbingNotes} from 'lib/testdata'

const testNotes = WestCoastClimbingNotes.map((n, index) => [
  index + 1,
  n.merge({id: index + 1})
])

const NotesReducerState = Record({
  notes: Map(testNotes)
})

const reducer = (state = NotesReducerState(), action = {}) => {
  switch (action.type) {
    case types.ADD_NOTE:
      const {note} = action
      // todo use combinereducers to handle the notes list
      return state.setIn(['notes', note.id], note)
    default:
      return state
  }
}

const types = getActionTypes('notes', [
  'ADD_NOTE'
])

const actions = {
  addNote: (note) => ({
    type: types.ADD_NOTE,
    note
  })
}

export {
  actions as notesActions,
  types as notesTypes,
  reducer as notesReducer
}
