import React from 'react'
import {NavLink, Link} from 'react-router-dom'
// import {ProductHuntBanner} from 'common/ProductHuntBanner'
import styles from './Navbar.module.css'

const L = (props) => (
  <NavLink className={styles.NavLink} activeClassName={styles.active} {...props} />
)

export class Navbar extends React.PureComponent {
  render () {
    return (
      <nav className={styles.Navbar}>
        <div className={styles.left}>
          <Link to='/' className={styles.brand}><h1>Trianglify.io</h1></Link>
          <L exact to='/' data-text='Generate' ><span>Generate</span></L>
          <L exact to='/about' data-text='FAQ' ><span>FAQ</span></L>
        </div>
        <div className={styles.center}>
          {/*<ProductHuntBanner />*/}
        </div>
        <div className={styles.right}>
          <a href='https://qrohlf.com' target='_blank' rel='noopener noreferrer'>by @qrohlf</a>
        </div>
      </nav>
    )
  }
}
