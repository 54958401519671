import trianglify from 'trianglify'
import {Record} from 'immutable'
import {withDecimals} from 'lib/utils'
import _ from 'lodash'
const withThreeDecimals = withDecimals(0, 3)
const noop = x => x

export const PatternOptions = Record({
  width: 1440,
  height: 900,
  xColors: 'random',
  variance: 0.5,
  cellSizeFractional: 0.3,
  seed: null,
  colorFunctionType: 'interpolateLinear',
  colorFunctionIntensity: 0.3
})

const getColorFunction = _.memoize((cfType) => _.memoize((cfIntensity) => {
  switch (cfType) {
    case 'interpolateLinear':
      return trianglify.colorFunctions.interpolateLinear(cfIntensity)
    case 'sparkle':
      return trianglify.colorFunctions.sparkle(cfIntensity / 2)
    case 'shadows':
      return trianglify.colorFunctions.shadows(cfIntensity * 2)
    default:
      throw TypeError('Unrecognized color function')
  }
}))

export const convertToTrianglifyOpts = (patternOpts) => {
  const {
    colorFunctionType,
    colorFunctionIntensity,
    cellSizeFractional,
    ...opts} = patternOpts.toJS()
  // eslint-disable-next-line
  const cellSize = Math.max(opts.width, opts.height) * cellSizeFractional
  const colorFunction = getColorFunction(colorFunctionType)(colorFunctionIntensity)
  return {...opts, cellSize, colorFunction}
}

const compressColors = colors => {
  switch (colors.constructor) {
    case String:
      return colors
    case Array:
      return colors
        .map(c => c.replace('#', '').toLowerCase())
        .join('.')
    default:
      throw Error('invalid type for colors')
  }
}

const decompressColors = colors => {
  switch (colors.indexOf('.')) {
    case -1:
      return colors
    default:
      return colors.split('.').map(c => '#' + c.toUpperCase())
  }
}

const compressColorFunctionType = (cfType) => {
  switch (cfType) {
    case 'interpolateLinear':
      return 'il'
    case 'sparkle':
      return 'sp'
    case 'shadows':
      return 'sh'
    default:
      throw TypeError('Unrecognized color function')
  }
}

const decompressColorFunctionType = (cfType) => {
  switch (cfType) {
    case 'il':
      return 'interpolateLinear'
    case 'sp':
      return 'sparkle'
    case 'sh':
      return 'shadows'
    default:
      throw TypeError('Unrecognized color function')
  }
}

const toStringKeyMapping = {
  width: {
    key: 'w',
    transform: int => Math.floor(int).toString(),
    inverse: str => parseInt(str, 10)
  },
  height: {
    key: 'h',
    transform: int => Math.floor(int).toString(),
    inverse: str => parseInt(str, 10)
  },
  xColors: {
    key: 'x',
    transform: compressColors,
    inverse: decompressColors
  },
  variance: {
    key: 'v',
    transform: withThreeDecimals,
    inverse: parseFloat
  },
  cellSizeFractional: {
    key: 'c',
    transform: withThreeDecimals,
    inverse: parseFloat
  },
  colorFunctionType: {
    key: 'f',
    transform: compressColorFunctionType, // TODO compress color function name
    inverse: decompressColorFunctionType
  },
  colorFunctionIntensity: {
    key: 'i',
    transform: withDecimals(2),
    inverse: parseFloat
  },
  seed: {
    key: 's',
    transform: noop,
    inverse: noop
  }
}

const fromStringKeyMapping = (
  Object.keys(toStringKeyMapping)
    .reduce((obj, k) => {
      const m = toStringKeyMapping[k]
      obj[m.key] = {key: k, transform: m.inverse}
      return obj
    }, {})
)

// todo these should have tests
// NOTE - if the serialized value is greater than 200 characters,
// Stripe will error
export const serializeToString = (patternOpts) => (
  patternOpts.toSeq().reduce((arr, v, k) => {
    const m = toStringKeyMapping[k]
    return arr.concat([`${m.key}:${m.transform(v)}`])
  }, []).join('!')
)

export const deserializeFromString = (patternString) => (
  PatternOptions(
    patternString
      .split('!')
      .map(x => x.split(':'))
      .reduce((obj, [k, v]) => {
        const m = fromStringKeyMapping[k]
        obj[m.key] = m.transform(v)
        return obj
      }, {})
  )
)
