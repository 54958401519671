import React from 'react'
import styles from './FormControl.module.css'
import cx from 'classnames'

export class FormControl extends React.PureComponent {
  render () {
    const {children, className, ...rest} = this.props
    return (
      <div className={cx(styles.FormControl, className)} {...rest}>
        {children}
      </div>
    )
  }
}
