import React from 'react'
import {AddIcon, ShuffleIcon} from 'common/icons'
import styles from './Palette.module.css'
import cx from 'classnames'

export class Palette extends React.PureComponent {
  render () {
    const {colors, tip, selected, onClick} = this.props
    return (
      <div
        onClick={onClick}
        className={cx(styles.Palette, selected && styles.selected)}
        data-tip
        data-tippy-delay='[800, 0]'
        title={tip}>
        {colors.map((c, i) => <div
          key={c + i}
          className={styles.swatch}
          style={{background: c}} />)}
      </div>
    )
  }
}

export class RandomPalette extends React.PureComponent {
  render () {
    const {selected, onClick} = this.props
    return <div
      onClick={onClick}
      className={cx(styles.Palette, styles.random, selected && styles.selected)}
      data-tip
      data-tippy-delay='[800, 0]'
      title='random'>
      <ShuffleIcon />
    </div>
  }
}

export class AddPalette extends React.PureComponent {
  render () {
    const {onClick} = this.props
    return <div
      onClick={onClick}
      className={cx(styles.Palette, styles.add)}
      data-tip
      data-tippy-delay='[800, 0]'
      title='Add a custom palette'>
      <AddIcon />
    </div>
  }
}
