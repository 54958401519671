import React from 'react'
import {Modal} from 'common/Modal'
import {Trianglify} from 'common/Trianglify'
import styles from './ModalWithPreview.module.css'

export class ModalWithPreview extends React.PureComponent {
  render () {
    const {children, patternOpts, onRequestClose} = this.props

    return (
      <Modal onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false}>
        <div className={styles.ModalWithPreview}>
          <div className={styles.top}>
            <Trianglify patternOpts={patternOpts} />
          </div>

          <div className={styles.bottom}>
            {children}
          </div>
        </div>
      </Modal>
    )
  }
}
