import React from 'react'
import {ModalWithPreview} from 'common/ModalWithPreview'
import trianglify from 'trianglify'
import {convertToTrianglifyOpts, serializeToString} from 'datatypes/PatternOptions'
import {ImageOutlineIcon, CopyOutlineIcon, CubeOutlineIcon} from 'common/icons'
import {CircleSpinner} from 'common/CircleSpinner'
import {copyToClipboard} from 'lib/utils'
import tippy from 'tippy.js'
import {tippyDefaults} from 'lib/tippyDefaults'
import styles from './ExportDialog.module.css'

const STRIPE_KEY = process.env.NODE_ENV === 'production'
  ? 'pk_live_nWu7KjdDOHn83GaVXWaBhWHe'
  : 'pk_test_CWoKz9nPBhxsXVHXGaP1OhMs'

const PATTERN_PRICE = '$8'

// TODO - this is lazy because I don't want to write a stripe mock for testing
const stripe = window.Stripe && window.Stripe(STRIPE_KEY)

const MAX_SIDE = 400 // 400px freebie image

const getScaledDimensions = (patternOpts, maxSide) => {
  if (patternOpts.width < maxSide && patternOpts.height < maxSide) {
    return {width: patternOpts.width, height: patternOpts.height}
  }
  // determine scale factor based on largest side
  const scaleFactor = (patternOpts.width > patternOpts.height)
    ? MAX_SIDE / patternOpts.width
    : MAX_SIDE / patternOpts.height

  return {
    width: Math.round(scaleFactor * patternOpts.width),
    height: Math.round(scaleFactor * patternOpts.height)
  }
}

export class ExportDialog extends React.PureComponent {
  state = {
    checkoutPending: false
  }

  componentDidMount () {
    window.location.href.includes('hi.florence') && this.triggerCheckoutFlow()
  }

  downloadFile = (name, content) => {
    const a = document.createElement('a')
    a.href = content
    a.download = name
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  // downloadSVG = (e) => {
  //   const {patternOpts} = this.props
  //   const opts = convertToTrianglifyOpts(patternOpts)
  //   const svg = trianglify(opts).svg()
  //   const s = new window.XMLSerializer()
  //   const svgString = s.serializeToString(svg)
  //   const base64 = window.btoa(svgString)
  //   const dataUri = 'data:image/svg+xml;base64,' + base64
  //   this.downloadFile('trianglify.svg', dataUri)
  // }

  downloadPNG = (e) => {
    const {patternOpts} = this.props
    const opts = convertToTrianglifyOpts(patternOpts)
    const dataUri = trianglify(opts).toCanvas(null, {scaling: false}).toDataURL("image/png")
    this.downloadFile('trianglify.png', dataUri)
  }

  triggerCheckoutFlow = () => {
    const {patternOpts} = this.props
    this.setState({checkoutPending: true})
    const transactionsEndpoint = process.env.NODE_ENV === 'production'
      ? `https://api.trianglify.io/transactions`
      : `http://localhost:5000/transactions`
    window.fetch(transactionsEndpoint, {
      method: 'POST',
      headers: {'content-type': 'application/json'},
      body: JSON.stringify({patternOptions: patternOpts.toJS(), flo: !!window.location.href.includes('hi.florence')})
    })
    .then(res => res.json())
    // .then(console.log)
    .then(res => {
      stripe.redirectToCheckout({
        sessionId: res.checkoutSessionId
      }).then((result) => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        window.alert(result.error.message)
      })
    })
  }

  downloadLowResPNG = (e) => {
    const {patternOpts} = this.props
    if (patternOpts.width < MAX_SIDE && patternOpts.height < MAX_SIDE) {
      this.downloadPNG()
      return
    }
    // if we got here, we need to resize the PNG
    const {width, height} = getScaledDimensions(patternOpts, MAX_SIDE)
    const opts = convertToTrianglifyOpts(patternOpts)
    const canvas = trianglify(opts).toCanvas(null, {scaling: false})
    const resizedCanvas = document.createElement('canvas')
    resizedCanvas.width = width
    resizedCanvas.height = height
    const resizedContext = resizedCanvas.getContext('2d')
    resizedContext.drawImage(canvas, 0, 0, width, height)
    const dataUri = resizedCanvas.toDataURL("image/png")
    this.downloadFile('trianglify-lowres.png', dataUri)
  }

  copyLink = (e) => {
    const {patternOpts} = this.props
    const patternStr = serializeToString(patternOpts)
    const origin = process.env.PUBLIC_URL || window.origin
    const url = `${origin}/p/${patternStr}`
    const success = copyToClipboard(url)
    success
      ? console.log('copy success', url)
      : console.warn('copy failed', url)
    if (success) {
      this.showCopyButtonSuccessTooltip()
    } else {
      const msg = `We weren't able to access the clipboard in your browser. You can still copy the link manually: ${url}`
      window.alert(msg)
    }
  }

  showCopyButtonSuccessTooltip = () => {
    this._copyLinkButton.setAttribute('title', 'Link copied!')
    !this._copyLinkButton._tippy && tippy(this._copyLinkButton, {
      ...tippyDefaults,
      trigger: 'manual',
      theme: 'manual'
    })
    this._copyLinkButton._tippy.show()
    setTimeout(this.hideCopyButtonTooltip, 1200)
  }

  hideCopyButtonTooltip = () => {
    this._copyLinkButton._tippy &&
      this._copyLinkButton._tippy.hide()
    this._copyLinkButton.setAttribute('title', '')
  }

  render () {
    const {patternOpts, onRequestClose} = this.props
    const {checkoutPending} = this.state

    return (
      <ModalWithPreview patternOpts={patternOpts} onRequestClose={onRequestClose} shouldCloseOnOverlayClick={false}>
        <div className={styles.exportButtons}>
          <button className={styles.button} onClick={this.triggerCheckoutFlow}>
            <div className={styles.icon}>
              <ImageOutlineIcon />
            </div>
            <div className={styles.heading}>
              Export High-Res PNG and SVG
              {' '}
              {checkoutPending && <CircleSpinner inline />}
            </div>
            <div className={styles.price}>{PATTERN_PRICE}</div>
          </button>
          <button
            ref={r => { this._copyLinkButton = r }}
            className={styles.button}
            onClick={this.copyLink}>
            <div className={styles.icon}>
              <CopyOutlineIcon />
            </div>
            <div className={styles.heading}>
              Copy Shareable Link
            </div>
          </button>
          <button data-tip className={styles.button} onClick={this.downloadLowResPNG}>
            <div className={styles.icon}>
              <CubeOutlineIcon />
            </div>
            <div className={styles.heading}>
              Download Low-Res Preview
            </div>
          </button>
        </div>
      </ModalWithPreview>
    )
  }
}
