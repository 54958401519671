import React from 'react'
import styles from './AboutView.module.css'
import {LayoutFixed} from 'common/layouts/LayoutFixed'

export class AboutView extends React.PureComponent {
  render () {
    return (
      <LayoutFixed>
        <div className={styles.AboutView}>
          <div className={styles.content}>
            <h1>About</h1>
            <p>
              My name is Quinn Rohlf, and I'm an engineer/designer based out of
              Portland, OR. You can find me as @qrohlf most places.
            </p>
            <p>
              Trianglify.io is a tool I wrote to generate low-poly backgrounds,
              textures, and vectors. It's based on an open-source library,
              {' '}<a href='https://github.com/qrohlf/trianglify' target='_blank' rel='noopener noreferrer'>Trianglify</a>,
              {' '}which I also wrote.
            </p>
            <h1>FAQ</h1>
            <p>
              <strong>What's the deal with copyright?</strong>
            </p>
            <p>
              You own the copyright to any patterns produced using this tool, just
              like you'd own the copyright to a design you made using Photoshop or
              Illustrator.
            </p>
            <p>
              <strong>Can I use the Trianglify javascript library on my website?</strong>
            </p>
            <p>
              Yes, as long as your website is open-source and complies with the
              terms of the GNU General Public License v3. If you're interested in
              purchasing a commercial license for the Trianglify javascript
              library, please <a href='mailto:qr@qrohlf.com'>get in touch</a>.
            </p>
            <p>
              <strong>Would you consider implementing X?</strong>
            </p>
            <p>
              Possibly. Feel free to file a feature request on the Trianglify
              issue tracker, but odds are it will be a while before I get back to
              hacking on Trianglify. If you've got pressing needs for your project,
              I usually have some availability for consulting, so please email me.
            </p>
          </div>
        </div>
      </LayoutFixed>
    )
  }
}
