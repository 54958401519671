import React, {Fragment} from 'react'
import trianglify from 'trianglify'
import {Palette, RandomPalette, AddPalette} from 'common/Palette'
import {SegmentedControl} from 'common/SegmentedControl'
import {OrderedMap} from 'immutable'
import styles from './PaletteList.module.css'

const tabs = OrderedMap({
  colorbrewer: 'Colorbrewer',
  custom: 'Custom'
})

export class PaletteList extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedTab: 'colorbrewer'
    }
  }

  onChangeTab = (tab) => {
    this.setState({selectedTab: tab})
  }

  render () {
    const {xColors, customPalettes, onChange, onRandom, onAddPalette} = this.props
    const {selectedTab} = this.state

    return (
      <div className={styles.PaletteList}>
        <SegmentedControl
          options={tabs}
          selected={selectedTab}
          onChange={this.onChangeTab} />
        {selectedTab === 'colorbrewer' &&
          <Colorbrewer
            xColors={xColors}
            onRandom={onRandom}
            onChange={onChange} />}
        {selectedTab === 'custom' &&
          <Custom
            customPalettes={customPalettes}
            xColors={xColors}
            onAddPalette={onAddPalette}
            onChange={onChange} />}
      </div>
    )
  }
}

class Colorbrewer extends React.PureComponent {
  render () {
    const {xColors, onRandom, onChange} = this.props
    return <Fragment>
      <RandomPalette
        selected={xColors === 'random'}
        onClick={onRandom} />
      {Object.keys(trianglify.utils.colorbrewer).map(key => (
        <Palette
          key={key}
          selected={xColors === key}
          onClick={() => onChange(key)}
          tip={`Colorbrewer ${key}`}
          colors={trianglify.utils.colorbrewer[key]} />
      ))}
    </Fragment>
  }
}

class Custom extends React.PureComponent {
  render () {
    const {xColors, onAddPalette, customPalettes, onChange} = this.props

    return <Fragment>
      <AddPalette onClick={onAddPalette} />
      {customPalettes.map((colors, i) => {
        return <Palette
          key={i} // not good
          selected={xColors === colors}
          onClick={() => onChange(colors)}
          colors={colors} />
      }
      )}
    </Fragment>
  }
}
