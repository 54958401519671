import React from 'react'
import styles from './DownloadView.module.css'
import {LayoutFixed} from 'common/layouts/LayoutFixed'
import {ImageOutlineIcon, CodeIcon} from 'common/icons'

export class DownloadView extends React.PureComponent {
  render () {
    const {checkoutSessionId} = this.props.match.params
    const previewURL = `https://trianglify-export.s3-us-west-2.amazonaws.com/${checkoutSessionId}-preview.png`
    const pngURL = `https://trianglify-export.s3-us-west-2.amazonaws.com/${checkoutSessionId}.png`
    const svgURL = `https://trianglify-export.s3-us-west-2.amazonaws.com/${checkoutSessionId}.svg`
    return (
      <LayoutFixed>
        <div className={styles.DownloadView}>
          <div className={styles.content}>
            <img
              alt='pattern preview'
              src={previewURL}
              className={styles.preview} />
            <h1>Pattern Purchase Complete</h1>
            <p style={{marginBottom: '20px'}}>Thank you for supporting Trianglify!</p>
            <p>
              <a className={styles.dlButton} href={svgURL}>
                <CodeIcon style={{fontSize: '24px', marginRight: '10px'}} />
                <span>Download SVG vector image</span>
              </a>
            </p>
            <p>
              <a className={styles.dlButton} href={pngURL}>
                <ImageOutlineIcon style={{fontSize: '24px', marginRight: '10px'}} />
                <span>Download PNG raster image</span>
              </a>
            </p>
            <p style={{marginTop: '40px', opacity: '0.5'}}>
              This link has also been sent to the email address
              <br/>you provided at checkout, in case you need to
              <br/>download the files in the future.</p>
          </div>
        </div>
      </LayoutFixed>
    )
  }
}
