import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {NewPatternView} from 'features/NewPatternView'
import {PatternsView} from 'features/PatternsView'
import {AboutView} from 'features/AboutView'
import {DownloadView} from 'features/DownloadView'

export const Router = () => (
  <BrowserRouter>
    <div>
      <Switch>
        <Route exact path='/' component={NewPatternView} />
        <Route path='/p/:patternStr' component={NewPatternView} />
        <Route path='/patterns' component={PatternsView} />
        <Route path='/download/:checkoutSessionId' component={DownloadView} />
        <Route path='/about' component={AboutView} />
      </Switch>
    </div>
  </BrowserRouter>
)
