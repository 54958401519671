import React from 'react'
import cx from 'classnames'
import styles from './SegmentedControl.module.css'

export class SegmentedControl extends React.PureComponent {
  render () {
    const {options, selected, onChange} = this.props
    return (
      <div className={styles.SegmentedControl}>
        {options.map((v, k) => <button
          key={k}
          className={cx(selected === k && styles.selected)}
          onClick={() => onChange(k)}>
          {v}
        </button>).valueSeq()}
      </div>
    )
  }
}
