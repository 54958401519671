export const tippyDefaults = {
  performance: true,
  animation: 'fade',
  distance: 5,
  arrow: true,
  arrowType: 'round',
  arrowTransform: 'scale(0.7, 0.8)',
  delay: [1200, 0], // [show, hide] delay
  duration: [300, 200] // [show, hide] transition duration
}
