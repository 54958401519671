import React from 'react'
import styles from './LayoutFixed.module.css'
import {Navbar} from 'common/Navbar/'
import cx from 'classnames'

export class LayoutFixed extends React.PureComponent {
  render () {
    const {children, blur, ...rest} = this.props
    return (
      <div className={cx(styles.LayoutFixed, blur && styles.blur)} {...rest}>
        <Navbar />
        <div className={styles.main}>
          {children}
        </div>
      </div>
    )
  }
}
