export const trackEvent = ({category, action, label}) => {
  if (!window.ga) { return }
  const ga = window.ga
  if (!ga.getAll) { return }
  const tracker = ga.getAll()[0]
  if (!tracker) { return }
  tracker.send({
    'hitType': 'event',          // Required.
    'eventCategory': category,   // Required.
    'eventAction': action,      // Required.
    'eventLabel': label
  })
}
