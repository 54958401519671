import React from 'react'
import {LayoutFixed} from 'common/layouts/LayoutFixed'
import {ExportDialog} from './ExportDialog'
import {PalettePicker} from './PalettePicker'
import {Sidebar} from './Sidebar'
import {Preview} from './Preview'
import {PatternOptions, deserializeFromString} from 'datatypes/PatternOptions'
import {List} from 'immutable'
import {randomSlug} from 'lib/utils'
import styles from './NewPatternView.module.css'
import {trackEvent} from 'lib/trackEvent'

const DIALOG_EXPORT = 'DIALOG_EXPORT'
const DIALOG_PALETTE_PICKER = 'DIALOG_PALETTE_PICKER'

// generate an attractive-looking default pattern
const generateRandomDefaultOptions = () => (
  PatternOptions({
    seed: randomSlug(),
    cellSizeFractional: 0.08 + Math.random() * 0.25,
    variance: Math.random(),
    colorFunctionType: Math.random() > 0.5 ? 'interpolateLinear' : 'sparkle'
  })
)

export class NewPatternView extends React.PureComponent {
  constructor (props) {
    super(props)

    // TODO - if the patternStr uses a custom palette, inject it into
    // customPalettes and show the 'custom palette' pane by default
    const defaultOpts = props.match.params.patternStr
      ? deserializeFromString(props.match.params.patternStr)
      : generateRandomDefaultOptions()

    // TODO
    const fromProductHunt = false //props.match.location.search.includes('ref=producthunt')

    this.state = {
      showDialog: null,
      patternOpts: defaultOpts,
      customPalettes: List(),
      fromProductHunt
    }
  }

  stripPatternFromURL = () => {
    const {history, match} = this.props
    if (match.params.patternStr) {
      history.push('/')
    }
  }

  onChangeOpt = (opt, v) => {
    const {patternOpts} = this.state
    this.setState({
      patternOpts: patternOpts.set(opt, v)
    })
    this.stripPatternFromURL()
  }

  onChangeOpts = (opts) => {
    const {patternOpts} = this.state
    this.setState({
      patternOpts: patternOpts.merge(opts)
    })
    this.stripPatternFromURL()
  }

  onClickExport = () => {
    trackEvent({category: 'export', action: 'trigger-modal', label: 'Export Modal opened'})
    this.setState({showDialog: DIALOG_EXPORT})
    // this._sidebar.refreshAd()
  }

  dismissDialog = () => {
    this.setState({showDialog: null})
  }

  onAddPalette = () => {
    this.setState({showDialog: DIALOG_PALETTE_PICKER})
  }

  onSavePalette = (newPalette) => {
    const {customPalettes, patternOpts} = this.state
    this.setState({
      customPalettes: customPalettes.unshift(newPalette),
      patternOpts: patternOpts.set('xColors', newPalette),
      showDialog: null
    })
    this.stripPatternFromURL()
  }

  render () {
    const {patternOpts, customPalettes, showDialog} = this.state

    return (
      <LayoutFixed blur={!!showDialog}>
        <div className={styles.NewPatternView}>
          <div className={styles.sidebar}>
            <Sidebar
              ref={r => { this._sidebar = r }}
              patternOpts={patternOpts}
              customPalettes={customPalettes}
              onChangeOpt={this.onChangeOpt}
              onChangeOpts={this.onChangeOpts}
              onAddPalette={this.onAddPalette} />
          </div>
          <div className={styles.preview}>
            <Preview
              patternOpts={patternOpts}
              onClickExport={this.onClickExport} />
          </div>
        </div>
        {showDialog === DIALOG_EXPORT &&
          <ExportDialog
            patternOpts={patternOpts}
            onRequestClose={this.dismissDialog} />}
        {showDialog === DIALOG_PALETTE_PICKER &&
          <PalettePicker
            patternOpts={patternOpts}
            onSavePalette={this.onSavePalette}
            onRequestClose={this.dismissDialog} />}
      </LayoutFixed>
    )
  }
}
